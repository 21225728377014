import React, { useEffect, useState } from 'react';

const noop = () => {};

let ReCaptcha;

export function Recaptcha({ verifyCallback, expiredCallback, elementID, ...rest }) {
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  useEffect(() => {
    if (!window.MSInputMethodContext && !document.documentMode) {
      ReCaptcha = require('react-recaptcha');
      setCaptchaLoaded(true);
    }
  }, []);
  return (
    <div {...rest}>
      {ReCaptcha && captchaLoaded && (
        <ReCaptcha
          elementID={elementID}
          sitekey="6LfehsQUAAAAAH3oX-ZoU37CTdpYyodLLawAXJxA"
          render="explicit"
          verifyCallback={verifyCallback}
          expiredCallback={typeof expiredCallback === 'function' ? expiredCallback : noop}
          hl="de"
        />
      )}
    </div>
  );
}
