import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import style from './sidebar.module.scss';
import StyledScrollbars from './styled-scrollbars';
import { CreateLocalStorageProvider } from '../localstorageprovider';
import { isFunction, isBrowser } from '../utils';
import { WindowSizeContext } from '../context/WindowSizeContext';

// the values have to correspond to the keys of the modifierClasses array
const SIDEBAR_STATE = Object.freeze({ CLOSED: 'closed', OPEN: 'open', FULL: 'full' });
const modifierClasses = { closed: style.closed, open: style.open, full: style.full };
const transitionDuration = 200;

// turn the string representation of the enum into the actual enum
const stringToEnum = key =>
  Object.values(SIDEBAR_STATE).find(state => key === state) || SIDEBAR_STATE.OPEN;
// turn the enum into its string representation
const enumToString = enu => (Object.values(SIDEBAR_STATE).includes(enu) ? enu : SIDEBAR_STATE.OPEN);

// create a new local storage instance to read/write the value of the sidebar state
const SidebarStorage = isBrowser && CreateLocalStorageProvider('sidebar');

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: stringToEnum(isBrowser ? SidebarStorage.get() : 'open')
    };
    this.timeout = null;
  }
  getHeight = () => {
    const height = ReactDOM.findDOMNode(this).clientHeight;
    return height;
  };
  toClosed = () => {
    clearTimeout(this.timeout);
    this.setState({
      current: SIDEBAR_STATE.CLOSED,
      animating: this.state.current !== SIDEBAR_STATE.CLOSED
    });

    SidebarStorage.set(enumToString(SIDEBAR_STATE.CLOSED));

    this.timeout = setTimeout(() => {
      this.setState({ animating: false });
    }, transitionDuration + 100);
  };

  toOpen = () => {
    clearTimeout(this.timeout);
    this.setState({
      current: SIDEBAR_STATE.OPEN,
      animating: this.state.current !== SIDEBAR_STATE.OPEN
    });

    SidebarStorage.set(enumToString(SIDEBAR_STATE.OPEN));

    this.timeout = setTimeout(() => {
      this.setState({ animating: false });
    }, transitionDuration + 100);
  };

  toFull = () => {
    clearTimeout(this.timeout);
    this.setState({
      current: SIDEBAR_STATE.FULL,
      animating: this.state.current !== SIDEBAR_STATE.FULL
    });

    SidebarStorage.set(enumToString(SIDEBAR_STATE.FULL));

    this.timeout = setTimeout(() => {
      this.setState({ animating: false });
    }, transitionDuration + 100);
  };

  render() {
    const { render } = this.props;
    const { animating, current } = this.state;
    let classNames =
      modifierClasses[this.state.current] +
      ' ' +
      current +
      (Boolean(this.props.className) ? ' ' + this.props.className : '');

    if (animating) classNames += ' ' + style.animating;

    return (
      <WindowSizeContext.Consumer>
        {isMobile => {
          const style = {};

          if (isMobile) {
            style.width = '100%';
            style.maxWidth = '100%';
          }

          return (
            <SidebarContent
              className={classNames}
              style={style}
              onPressOpen={current === SIDEBAR_STATE.CLOSED ? this.toOpen : this.toFull}
              onPressClose={current === SIDEBAR_STATE.FULL ? this.toOpen : this.toClosed}
            >
              <StyledScrollbars
                onHeightCalc={this.props.onScrollbarHeightCalc}
                getScrollContentHeight={
                  typeof this.props.getScrollContentHeight === 'function'
                    ? this.props.getScrollContentHeight
                    : () => {}
                }
                onScroll={this.props.onScroll}
                iOSHeight={'81%'}
              >
                <div style={{ paddingRight: 30, paddingTop: 38, paddingBottom: 60 }}>
                  {isFunction(render) && render(current)}
                </div>
              </StyledScrollbars>
            </SidebarContent>
          );
        }}
      </WindowSizeContext.Consumer>
    );
  }
}

const SidebarContent = ({
  onPressOpen,
  onPressClose,
  children,
  className,
  style: styleOverrides
}) => {
  return (
    <aside
      className={style.sidebar + ' ' + className}
      style={{ ...styleOverrides, transitionDuration: transitionDuration + 'ms' }}
    >
      <WindowSizeContext.Consumer>
        {isMobile => {
          if (!isMobile) {
            return (
              <div className={style.sidebarNav}>
                <button className={style.openButton} onClick={onPressOpen}>
                  Öffnen
                </button>
                <button className={style.closeButton} onClick={onPressClose}>
                  Schließen
                </button>
              </div>
            );
          }
        }}
      </WindowSizeContext.Consumer>
      <div className={style.content}>{children}</div>
      {/* <Link to="/map/district-a" state={stateForLink}>
        District A
      </Link> */}
    </aside>
  );
};
